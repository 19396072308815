<template>
  <div class="content-wrapper">
    <Toolbar :back-btn="true" />
    <FaqBar />
    <router-view />
  </div>
</template>

<script>
// @ is an alias to /src
// import Toolbar from '@/components/Toolbar.vue';
import Toolbar from '@/components/menu/Toolbar.vue';
import FaqBar from '@/components/menu/FaqBar.vue';

export default {
  name: 'Layout',
  components: {
    Toolbar,
    FaqBar,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.content {
  /*height: calc(100vh - 65px);*/
  /*overflow-y: scroll;*/
  /*left: 65px;*/
}
</style>
