<template>
  <div v-if="!isLive" style="z-index: 5;">
    <b-modal ref="faq-video-modal" hide-footer id="faq-video-modal">
      <!-- <iframe srcdoc="&nbsp;" ref="live-print-content"></iframe> -->
      <iframe
        src="https://www.youtube.com/embed/23f6wyIQcgg?rel=0&autoplay=1"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </b-modal>

    <button
      :class="{ faq: true }"
      ref="faq-toggle"
      @click="toggleFaq"
      v-if="account.whitelabel_id === null">
      <span class="icon">
        <i class="far fa-question-circle"></i>
      </span>
      <span class="label">{{$t('generic-str.help')}}</span>
    </button>
    <div class="faq-source" v-if="account.whitelabel_id === null">
      <p>
        <button @click="toggleFaqVideo">
          <span>
            {{$t('generic-str.instructions-video')}} <i class="fab fa-youtube"></i>
          </span>
        </button>
      </p>
      <p>
        <a :href="linkFaq" target="_blank" @click="toggleFaq">
          <span>
            {{$t('generic-str.menu.knowledge-center')}}
            <i class="fas fa-external-link-alt fa-fw"></i>
          </span>
        </a>
      </p>
      <p>
        <router-link to="/support" @click="toggleFaq" v-if="!isSupport">
          <span>
            {{$t('generic-str.open-call')}}
            <i class="fas fa-external-link-alt fa-fw"></i>
          </span>
        </router-link>
      </p>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
// import Toolbar from '@/components/Toolbar.vue';
export default {
  name: 'FaqBar',
  components: {
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
    isLive() {
      console.log('isLive', this.$route.path.split('/'));
      return this.$route.path.split('/')[1] === 'live';
    },
  },
  data() {
    return {
      isSupport: false,
      linkFaq: null,
    };
  },
  mounted() {
    this.updateLinkFaq();
  },
  methods: {
    updateLinkFaq() {
      const path = this.$router.currentRoute.path.split('/')[1];

      let link;
      // let isLive = false;
      let isSupport = false;

      switch (path) {
        case 'mail':
          link = 'https://yup.chat/faq/e-mail-transacional/';
          break;
        case 'rcs':
          link = 'https://yup.chat/faq/categoria/rcs';
          break;
        case 'sms':
          link = 'https://yup.chat/faq/categoria/sms';
          break;
        // case 'live':
        //   link = 'https://yup.chat/faq/categoria/live';
        //   isLive = true;
        //   break;
        case 'whatsapp':
          link = 'https://yup.chat/faq/categoria/whatsapp-business';
          break;
        case 'support':
          link = 'https://yup.chat/faq/';
          isSupport = true;
          break;
        default:
          link = 'https://yup.chat/faq/';
      }

      this.isSupport = isSupport;
      // this.isLive = isLive;
      this.linkFaq = link;

      setTimeout(() => this.updateLinkFaq(), 200);
    },
    toggleFaq() {
      console.log('show FAQ!');

      const faqs = this.$refs['faq-toggle'];

      if (!faqs) {
        console.error('this.$refs[livePrintModal] not found');
        return;
      }

      faqs.classList.toggle('active');
    },
    async toggleFaqVideo() {
      console.log('show FAQ!');

      this.toggleFaq();

      const modal = this.$refs['faq-video-modal'];

      if (!modal) {
        console.error('this.$refs[livePrintModal] not found');
        return;
      }

      await modal.show();
    },
  },
};
</script>

<style lang="scss" scoped>

.faq {
  border: none;
  background: #ffc100;
  position: fixed;
  z-index: 1001;
  bottom: 15px;
  right: 15px;
  height: 48px;
  min-width: 48px;
  border-radius: 24px;
  padding: .1em;
  font-size: 16pt;
  outline: none;
  box-shadow: 0 2px 2px rgba(0, 0, 0, .2);
  text-align: left;

  .icon, .label {
    vertical-align: middle;
    display: inline-block;
  }

  .icon {
    text-align: center;
    width: 48px;
  }

  i {
    color: inherit;
  }

  .label {
    transition: .2s all ease;
    overflow: hidden;
    width: 0;
    margin: 0;
    font-size: 10pt;
  }

  &.active {
    box-shadow: 0 2px 2px rgba(0, 0, 0, .2),
                0 0 100px 10px rgba(0, 0, 0, .6);
  }

  &.active .label {
    margin: 0 5px;
    width: 52px;
  }

  & ~ .faq-source {
    position: fixed;
    bottom: 68px;
    right: -1000px;
    z-index: 1002;
  }

  &.active ~ .faq-source {
    right: 25px;
  }

  @media (max-width: 1230px) {
    &.is-live {
      bottom: 115px;
    }

    &.is-live ~ .faq-source {
      bottom: 177px;
    }
  }
}

.faq-source {
  transition: .2s all ease;

  & > p {
    margin-bottom: .8em;

    i {
      vertical-align: middle;
      margin-left: 5px;
      font-size: 14pt;
      color: inherit;
    }

    &::after {
      clear: both;
      content: "";
      display: block;
      height: 0;
    }

    & > * {
      overflow: hidden;
      color: #212529;
      float: right;
      outline: none;
      border: none;
      padding: .5em .9em;
      background: #fff;
      border-radius: .2em;
      box-shadow: 1px 2px 8px rgba(70,70,70,.25);
      transition: .2s all ease;
      position: relative;

      span {
        pointer-events: none;
        position: relative;
        z-index: 2;
      }

      &::before {
        background: #efefef;
        position: absolute;
        z-index: 1;
        left: -101%;
        top: 0;
        width: 100%;
        height: 100%;
        content: "";
        transition: .1s all ease;
      }

      &:hover::before {
        left: 0;
      }
    }
  }
}
</style>

<style lang="scss">
#faq-video-modal {
  border: none;

  [aria-label="Close"] {
    font-size: 32pt;
    color: #fff;
    padding: .2em;
  }

  .modal-dialog {
    top: 50%;
    max-width: 80vw;
    min-width: 220px;
    transform: translateY(-50%);
    margin: 0 auto !important;
  }

  .modal-header {
    padding-top: 0 !important;
  }

  .modal-content, .modal-header {
    background: transparent;
    border: none;
  }

  .modal-body {
    color: #fff;
    background: #000;
    border-radius: 0;
    padding: 0;
    min-height: 80vh;
    height: 80vh;

    iframe {
      background: transparent;
      border: none;
      height: 100%;
      width: 100%;
    }
  }
}
</style>
